import React from 'react';
import { graphql } from 'gatsby';
import ProjectPreview from '../components/project-preview'
import Layout from '../components/layout';
import styled from 'styled-components';
import { fadeInUp } from '../utils/m-styled-animations'


export const query = graphql`
    {
        project: allContentfulProject(filter: {category: {eq: "Video"}}) {
            edges {
            node {
                title
                category
                shortDescription
                images {
                    fluid (maxWidth: 3080, quality: 100){
                      ...GatsbyContentfulFluid
                    }
                  }
                tags
                slug
            }
            }
      },
      video: allContentfulVideoProject {
        nodes {
          title
          tags
          slug
          shortDescription
          category
          previewImage {
            fluid (maxWidth: 3080, quality: 100){
              ...GatsbyContentfulFluid
          }
        }
        }
      }
    }
`

const VideoWrapper = styled.div`
    border: solid 1px var(--gray-400);
    border-radius: 5px;
    margin: 3rem auto;
    max-width: 95%;
    width: 80ch;
    display: grid;
    grid-template-rows: auto auto;
    grid-row-gap: 0.3rem;
    background: ${({ theme }) => theme.body};
    animation: ${fadeInUp} 2s;
    position: relative;

  /*desktop version*/
    @media (min-width: 700px) {
        display: grid;
        margin-top: 5rem;
        grid-template-columns: 1fr 1.5fr;
        grid-template-rows: 1fr;
        grid-auto-flow: column;
      
}

`
// <VideoWrapper dangerouslySetInnerHTML={{ __html: data.video.nodes[0].childContentfulVideoProjectMarkdownContentTextNode.markdownContent }} />

const Video = ({ data }, props) => {
  const projects = data.video.nodes;
  return (
    <Layout>

      <div>
        {projects.map((project) => {
          const title = project.title;
          const category = project.category;
          const slug = project.slug;
          const tags = project.tags;
          const description = project.shortDescription;
          const imageData = project.previewImage.fluid;
          return (
            <div key={title}>
              <ProjectPreview
                key={title}
                title={title}
                slug={slug}
                description={description}
                imageData={imageData}
                tags={tags}
                category={category}
              />
            </div>
          )
        })
        }
      </div>
    </Layout>
  )
}

export default Video;
